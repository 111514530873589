:root {
    font-family: Roboto, Arial, Helvetica, sans-serif;
}
@font-face {
    font-family: "Posterman";
    src: url("./assets//fonts//Posterman.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}
#particle canvas {
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 100%;
    color: hsla(0, 0%, 100%, 0.048);
}
/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Works for Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
